import React from 'react'
import Form from '@bootstrap-styled/v4/lib/Form'
import styled from 'styled-components'
import Button from '../Button'
import GoogleSuburbsSelection from './GoogleSuburbsSelection'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BookingForm = styled(Form)`
  background-color: #fff;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.19);
  position: relative;
  
  border: 1px solid ${props => props.theme['$colors']['blue']};
  
  @media(max-width:768px) {
    padding: 10px 12px 14px;
    flex-direction: column;
  }
  
  .typeahead {
    display: block;
    width: auto;
    flex-grow: 1;
    position: relative;
  }
  .typeahead-selector {
    @media (min-width:768px) {
      min-width: 350px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    margin-top: -15px;
  }
  .booking-input {
    display: block;
    width: 100%;
    border: none;
    font-size: 17px;
    padding: 18px 9px;
    outline: none;
    @media(max-width:768px) {
      font-size: 16px;
      padding: 8px 4px;
      margin: 8px 0 24px;
      border-bottom: 2px solid ${props => props.theme['$colors']['blue']};
    }
  }
  .btn-lg {
    min-width: 250px;
    padding: 19px 24px;
  }
  @media (max-width:768px) {
    .btn-lg {
      padding: 12px 24px !important;
      font-size: 17px;
    }
  }
`

function BookingInput ({
  onSubmit,
  focus = false,
  busy: parentBusy = false,
  ...props
}) {
  const [busy, setBusy] = React.useState(false)
  const [predictedSuburb, setPredictedSuburb] = React.useState(null)

  return <BookingForm className={focus ? 'form-focus' : ''} {...props}>
    <GoogleSuburbsSelection
      name={'suburbInput'}
      onLoading={v => setBusy(v)}
      inputClass={'booking-input'}
      placeholder={'Enter a suburb to book a nearby clinic'}
      onOptionSelected={v => setPredictedSuburb(v)}
    />
    <Button size={'lg'} disabled={busy || parentBusy} onClick={() => predictedSuburb ? onSubmit(predictedSuburb) : null}>
      { (busy || parentBusy) ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Book an appointment' }
    </Button>
  </BookingForm>
}

export default BookingInput

import React from 'react'
import Collapse from '@bootstrap-styled/v4/lib/Collapse'
import { Article } from './content'
import { H4 } from './Typography'
import styled from 'styled-components'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Handle = styled(H4)`
  margin: 0;
  cursor: pointer;
  font-size: 16px;
  padding: 25px 0;
  color: ${props => props.theme.$colors['blue']}
`

const Block = styled(Article)`
  border-top: 1px solid #e8e8e8;
  &:last-child {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 25px;
  }
`

function FAQ ({ items }) {

  const [isOpen, setOpen] = React.useState(items.map(() => false))
  const [rotation, setRotation] = React.useState(items.map(() => 0))

  const toggle = (index) => {
    let opened = [...isOpen]
    for (let i = 0; i < index; i++) {
      if (opened.length - 1 < i) {
        opened.push(false)
        rotation.push(0)
      }
    }
    opened[index] = !opened[index]
    rotation[index] = rotation[index] === 0 ? 90 : 0
    setOpen(opened)
    setRotation(rotation)
  }

  return (
    <div>
      {items.map((item, i) => <Block itemScope itemType={'http://schema.org/Question'} key={`faq-item-${i}`}>
        { item.title && <Handle itemProp={'name'} onClick={() => toggle(i)}>{item.title} <FontAwesomeIcon className={'float-right mr-3'} rotation={rotation[i]} icon={faChevronRight}/></Handle> }
        { item.title ? <Collapse isOpen={!!isOpen[i]}>
          <div itemProp={'text'} className={'mt-3'}>{ item.content }</div>
        </Collapse> : <div itemProp={'text'}>{item.content}</div> }
      </Block>)}
    </div>
  )
}

export default FAQ
